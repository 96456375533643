<template>

  <div>

    <b-button
      v-if="hits.length"
      class="bg-gradient-primary mr-1"
      variant="primary"
      @click="playAll"
    >
      <feather-icon
        size="13"
        icon="PlayIcon"
        fill="#FFF"
        cursor="pointer"
      />
      Ouvir
    </b-button>

    <b-button
      v-if="hits.length && filtrar >= 0 && !campanhaId"
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      v-b-modal.modal-filtro
      variant="dark"
    >
      <feather-icon
        size="13"
        icon="FilterIcon"
        cursor="pointer"
      />
      Filtros
    </b-button>

    <b-button
      v-if="filtrar > 0"
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      variant="outline-dark"
      class="ml-1"
      @click="limparFiltro()"
    >
      <feather-icon
        size="13"
        icon="FilterIcon"
        cursor="pointer"
      />
      Limpar
    </b-button>

    <div class="d-flex mt-1">
      <b-form-checkbox
        checked="true"
        class="custom-control-primary"
        name="check-button"
        v-model="filtroReproduzido"
        switch
      />
      <b-card-text class="align-middle">
      Ouvir <b>músicas já reproduzidas</b>
      </b-card-text>
    </div>

    <b-modal
      id="modal-filtro"
      ok-only
      ok-title="Aplicar"
      centered
      no-close-on-backdrop
      size="lg"
      title="Filtrar Pesquisa"
      @ok="filtrarPesquisa"
    >

      <validation-observer
        #default="{invalid}"
        ref="temaForm"
      >
        <!-- Form -->
        <b-form
          ref="temaForms"
          class="p-2"
          @submit.prevent="hitTemaCadastrar"
        >

          <b-row>

            <b-col
              xl="6"
              cols="12"
            >

              <!-- Estilos -->
              <b-form-group
                label="Estilo"
                label-for="hitEstilo"
              >
                <validation-provider
                  #default="{ errors }"
                  name="hitEstilo"
                >
                  <v-select
                    v-model="hitEstilos"
                    multiple
                    label="nome"
                    :options="estilos"
                    :reduce="(option) => option.id"
                  />
                </validation-provider>
              </b-form-group>
              <!-- Estilos -->

            </b-col>

            <b-col
              xl="6"
              cols="12"
            >

              <!-- Temas -->
              <b-form-group
                label="Tema"
                label-for="hitTema"
              >
                <validation-provider
                  #default="{ errors }"
                  name="hitTema"
                >
                  <v-select
                    v-model="hitTemas"
                    multiple
                    label="nome"
                    :options="temas"
                    :reduce="(option) => option.id"
                  />
                </validation-provider>
              </b-form-group>
              <!-- Temas -->

            </b-col>

          </b-row>

          <b-row>

            <b-col
              xl="6"
              cols="12"
            >

              <b-form-group
                label="Valor de Liberação"
                label-for="hitCompositores"
              >
                <validation-provider
                  #default="{ errors }"
                  name="hitCompositores"
                >

                  <vue-slider
                    v-model="hitValorLiberacao"
                    :min="0"
                    :max="100000"
                    :tooltip="'none'"
                    class="mb-2"
                    @change="alterarValorLiberacao"
                  />

                  <div class="d-flex">
                    <b-form-group>
                      <money
                        v-model="filtroLiberacaoMin"
                        v-bind="money"
                        class="form-control lg"
                        @input="alterarValorLiberacaoInput"
                      />
                    </b-form-group>
                    <b-form-group class="ml-2">
                      <money
                        v-model="filtroLiberacaoMax"
                        v-bind="money"
                        class="form-control lg"
                        @input="alterarValorLiberacaoInput"
                      />
                    </b-form-group>
                  </div>

                </validation-provider>

              </b-form-group>

            </b-col>

            <b-col
              xl="6"
              cols="12"
            >

              <b-form-group
                label="Valor de Exclusividade"
                label-for="hitCompositores"
              >
                <validation-provider
                  #default="{ errors }"
                  name="hitCompositores"
                >

                  <vue-slider
                    v-model="hitValorExclusividade"
                    :min="0"
                    :max="100000"
                    :tooltip="'none'"
                    class="mb-2"
                    @change="alterarValorExclusividade"
                  />

                  <div class="d-flex">
                    <b-form-group>
                      <money
                        v-model="filtroExclusividadeMin"
                        v-bind="money"
                        class="form-control lg"
                        @input="alterarValorExclusividadeInput"
                      />
                    </b-form-group>

                    <b-form-group class="ml-2">
                      <money
                        v-model="filtroExclusividadeMax"
                        v-bind="money"
                        class="form-control lg"
                        @input="alterarValorExclusividadeInput"
                      />
                    </b-form-group>
                  </div>

                </validation-provider>

              </b-form-group>

            </b-col>

          </b-row>

        </b-form>
      </validation-observer>

    </b-modal>

    <!-- Modal de Negociação -->

    <b-modal
      id="modal-negociar"
      ref="modalNegociar"
      ok-only
      ok-title="Enviar Proposta"
      centered
      no-close-on-backdrop
      size="sm"
      title="Negociar Hit"
      :hit-id="hitIdNegociarSelecionado"
      :hit-nome="hitNomeNegociarSelecionado"
      :hit-valor-exclusividade="hitValorExclusividadeNegociarSelecionado"
      :hit-valor-liberacao="hitValorLiberacaoNegociarSelecionado"
      @ok="negociarHit"
    >

      <template v-slot:modal-footer>
        <b-button
          block
          variant="primary"
          :disabled="invalid || respostaApi"
          @click="negociarHit"
        >
          Enviar Proposta
          <b-spinner
            v-if="respostaApi"
            small
            label="Carregando"
          />
        </b-button>
      </template>

      <h5>{{ hitNomeNegociarSelecionado }}</h5>

      <b-alert
        variant="primary"
        show
        class="mt-1"
      >
        <div class="alert-body">
          <span><strong>Envie a sua proposta!</strong> Essas são as sugestões do compositor.</span>
        </div>
      </b-alert>

      <b-row class="mt-2">

        <b-col>
          <!-- media -->
          <b-media
            no-body
          >
            <b-media-aside class="mr-1">
              <b-avatar
                rounded
                variant="light-primary"
                size="34"
              >
                <feather-icon
                  icon="HomeIcon"
                  size="18"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body>
              <h6 class="mb-0">
                Liberação
              </h6>
              <small>{{ hitValorLiberacaoNegociarSelecionado | currency }}</small>
            </b-media-body>
          </b-media>
        </b-col>

        <b-col>
          <!-- media -->
          <b-media
            no-body
          >
            <b-media-aside class="mr-1">
              <b-avatar
                rounded
                variant="light-primary"
                size="34"
              >
                <feather-icon
                  icon="HomeIcon"
                  size="18"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body>
              <h6 class="mb-0">
                Exclusividade
              </h6>
              <small>{{ hitValorExclusividadeNegociarSelecionado | currency }}</small>
            </b-media-body>
          </b-media>
        </b-col>

      </b-row>

      <validation-observer
        #default="{invalid}"
        ref="negociacaoForm"
      >

        <!-- Form -->
        <b-form
          ref="negociacaoForms"
          class="mt-2"
          @submit.prevent="negociarHitCadastrar"
        >

          <b-row>

            <b-col>

              <!-- Tipo neogciação -->
              <b-form-group
                label="Tipo de Negociação"
                label-for="hitTipoNegociacao"
              >
                <validation-provider
                  #default="{ errors }"
                  name="hitTipoNegociacao"
                >

                  <v-select
                    v-model="tipoNegociacaoSelecionada"
                    label="title"
                    :options="tipoNegociacao"
                  />

                </validation-provider>
              </b-form-group>
              <!-- Tipo neogciação -->

            </b-col>

          </b-row>

          <b-row>

            <b-col>

              <!-- Tempo  -->
              <b-form-group
                label="Tempo de Venda"
                label-for="hitTempoVenda"
              >
                <validation-provider
                  #default="{ errors }"
                  name="hitTempoVenda"
                >

                  <v-select
                    v-model="tempoVendaSelecionada"
                    label="title"
                    :options="tempoVenda"
                  />

                </validation-provider>
              </b-form-group>
              <!-- Tempo -->

            </b-col>

          </b-row>

          <b-row>

            <b-col>

              <!-- Estilos -->
              <b-form-group
                label="Valor"
                label-for="hitValorPropostaLabel"
              >
                <validation-provider
                  #default="{ errors }"
                  name="hitValorPropostaLabel"
                >

                  <money
                    v-model="hitValorProposta"
                    v-bind="money"
                    class="form-control lg"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- Estilos -->

            </b-col>

          </b-row>

        </b-form>
      </validation-observer>

    </b-modal>

    <section
      id="knowledge-base-content"
      ref="musicList"
      class="divHits pt-2 pb-5"
    >

    <div 
        v-if="!hits.length && filtrar == 0 && !carregamentoApi"
      >

          <b-col
            cols="12"
            style="padding: 0px;"
          >
            <b-card
              :no-body="true"
              class="text-left p-3"
              style="overflow: hidden;"
            >
              
              <b-card-body>

                <div style="position: absolute; top: 0; right: 0;z-index:0;">
                  <img src="@/assets/images/bg/bg.png" alt="Ícone Favoritos" />
                </div>

                <div style="position: relative;z-index:2;">
                  <b-card-title class="text-white">
                    <h1 v-if="!campanhaId" class="content-header-title">Bem-vindo ao <b>Mercado de Hits</b></h1>
                    <h1 v-if="campanhaId" class="content-header-title">Bem-vindo aos <b>Hits Enviados</b></h1>
                  </b-card-title>
                  <b-card-text class="my-2">
                    <h6>
                      <b>Aqui você encontrará todos os 
                       hits enviados por compositores.</b>
                      <br>No momento ainda não possui nenhum hit cadastrado.        
                    </h6>
                  </b-card-text>
                </div>

              </b-card-body>

            </b-card>
          </b-col>

      </div>

      <b-card
        v-for="hit in hits"
        :key="hit.id"
        :ref="checkActiveMusic(hit.id) ? 'activeMusic' : ''"
        :class="checkActiveMusic(hit.id) ? 'active-color' : ''"
        class="card-transaction hitPlayCard"
        no-body
      >

        <b-card-body>
          <div class="transaction-item">
            <b-media no-body>
              <b-media-aside>
                <div v-if="checkActiveMusic(hit.id) && getPlayingStatus"
                  class="boxContainer"
                >
                  <div class="box box1"></div>
                  <div class="box box2"></div>
                  <div class="box box3"></div>
                  <div class="box box4"></div>
                  <div class="box box5"></div>
                </div>

                <b-icon
                  v-else
                  font-size="30"
                  icon="play-circle"
                  cursor="pointer"
                  class="btPlay"
                  @click="selectMusic(hit);updateMusicListScroll($event);"
                />

              </b-media-aside>
              <b-media-body>
                <h6 class="transaction-title">
                  <span :class="checkActiveMusic(hit.id) ? 'active-text-color' : ''">{{ hit.nomeComposicao }}</span>
                </h6>
                <div class="audio-player" />
              </b-media-body>
            </b-media>

            <div class="d-flex align-items-center font-weight-bolder">
              <div
              :id="'negociar-tooltip-' + hit.id"
              class="mr-2"
              style="cursor: pointer; font-size: 20px;"
              @click="modalNegociar(hit.id, hit.nomeComposicao, hit.valorExclusividade, hit.valorLiberacao)"
              >
                <b-tooltip :target="'negociar-tooltip-' + hit.id" placement="top"
                    container="body">
                  Negociar
                </b-tooltip>
                <font-awesome-icon class="font-awesome-icon-ajustes" :icon="['fas', 'handshake-simple']" />
              </div>
              
                <div :id="'star-icon-tooltip-' + hit.id" class="mr-2">
                  <b-tooltip :target="'star-icon-tooltip-' + hit.id" placement="top"
                      container="body" >
                    Favoritar
                  </b-tooltip>
                  <feather-icon
                    size="20"
                    icon="StarIcon"
                    :class="{favorito: hit.favorito}"
                    style="cursor: pointer"
                    @click="favoritarHit(hit.id, hit.favorito)"
                    />
                </div>

                <div :id="'plus-icon-tooltip-' + hit.id">
                  <b-tooltip :target="'plus-icon-tooltip-' + hit.id" placement="top"
                      container="body">
                    Adicionar na playlist
                  </b-tooltip>
                  <feather-icon
                    icon="PlusIcon"
                    size="20"
                    class="align-middle text-body"
                    style="cursor: pointer"
                    @click="sidebarPlaylist(hit.id)"
                    />
                </div>
            </div>      
          </div>
        </b-card-body>

      </b-card>
      <hit-comprador-playlist-musica-add
        :adicionarMusicaPlaylistSideBarAtivo.sync="adicionarMusicaPlaylistSideBarAtivo"
        :hit-id="hitIdPlaylistSelecionado || 0"
      />
      
      <infinite-loading
        ref="infiniteLoading"
        :infinite-scroll-disabled="carregamentoApi"
        @infinite="hitsLista"
        :identifier="filtrar"
        no-results="noResults"
      >

        <span slot="spinner">
          <img
            src="@/assets/images/logo/logo-loading.gif"
            width="40px"
          >
          <p>Carregando mais hits...</p>
        </span>
        <span slot="no-more"></span>
        <span slot="loading">Carregando mais hits...</span>

        <div slot="no-results">

          <div
            v-if="filtrar > 0"
          >
            <b-badge
              variant="light-danger"
            >
              Sem resultados
            </b-badge>
          </div>

        </div>

      </infinite-loading>

    </section>

    <!-- <HitPlayer class="footer fixed-bottom bg-dark" /> -->

  </div>
</template>

<script>

import Vue from 'vue'

import {
  BAlert, BRow, BCol, BModal, BCard, BCardTitle, BCardHeader, BCardBody, BForm, BFormInvalidFeedback, BFormGroup, BInputGroup, BFormInput, BCardText, BInputGroupPrepend, IconsPlugin, BBadge, BButton, BMediaBody, BMedia, BMediaAside, BAvatar, BDropdown, BDropdownItem, BSpinner, BFormCheckbox,
  BSidebar, BTooltip
} from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'

import vSelect from 'vue-select'

import useJwt from '@/auth/jwt/useJwt'

import VueAudio from 'vue-audio'

import AudioPlayer from '@liripeng/vue-audio-player'

// CARREGANDO DE PÁGINA PARA API
import axios from 'axios'

import aws4 from 'aws4'

import globalMixins from '@/mixins/globalMixins'
import musicsMixins from '@/mixins/musicsMixins'

import VueSlider from 'vue-slider-component'

import { Money } from 'v-money'

import Ripple from 'vue-ripple-directive'

import VueCurrencyFilter from 'vue-currency-filter'
import InfiniteLoading from 'vue-infinite-loading'
import HitPlayer from './HitPlayer.vue'
import HitPlay from './HitPlay'
import HitCompradorPlaylistMusicaAdd from '../usuarios/comprador/comprador-editar/HitCompradorPlaylistMusicaAdd.vue' 

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faHandshakeSimple } from '@fortawesome/free-solid-svg-icons';

library.add(faHandshakeSimple);

Vue.use(VueCurrencyFilter, {
  symbol: 'R$',
  thousandsSeparator: '.',
  fractionCount: 2,
  fractionSeparator: ',',
  symbolPosition: 'front',
  symbolSpacing: true,
})

export default {
  name: 'AllMusicsPlayList',
  components: {
    BSidebar,
    BTooltip,
    BAlert,
    BRow,
    BCol,
    BModal,
    BCard,
    BCardTitle,
    BCardHeader,
    BCardBody,
    BCardText,
    BForm,
    BFormGroup,
    BFormInvalidFeedback,
    BInputGroup,
    BInputGroupPrepend,
    IconsPlugin,
    BFormInput,
    BBadge,
    BButton,
    BMediaBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BDropdown,
    BDropdownItem,
    BSpinner,
    BFormCheckbox,
    AudioPlayer,
    aws4,
    HitPlayer,
    HitCompradorPlaylistMusicaAdd,
    FontAwesomeIcon,

    // Form Validation
    ValidationProvider,
    ValidationObserver,

    vSelect,
    VueSlider,
    Money,
    InfiniteLoading,
    VueCurrencyFilter,
  },
  directives: {
    Ripple,
  },
  props: {
    campanhaId: {
      type: Number,
      default: 0,
    },
  },
  mixins: [globalMixins, musicsMixins],
  data() {
    return {

      hits: [],
      carregamentoApi: false,
      audioList: ['http://www.ytmp3.cn/down/76865.mp3'],
      tocando: false,
      selected_options: [],
      activePlayList: 'all',

      filtrar: 0,

      page: 0,
      size: 8,
      sort: 'ASC',

      // FILTRO

      hitValorLiberacao: [0, 100000],
      hitValorExclusividade: [0, 100000],

      // ESTILOS

      estilos: [],
      hitEstilos: [], // Estilos selecionados

      // TEMAS

      temas: [],
      hitTemas: [], // Temas selecionados

      filtroLiberacaoMin: 0,
      filtroLiberacaoMax: 100000,
      filtroExclusividadeMin: 0,
      filtroExclusividadeMax: 100000,

      // Ouvir músicas já reproduzidas
      filtroReproduzido: false,

      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
        masked: false,
      },

      // FILTRO

      // Hit Selecionado para Modal Negociar

      hitIdNegociarSelecionado: null,
      hitNomeNegociarSelecionado: null,
      hitValorExclusividadeNegociarSelecionado: null,
      hitValorLiberacaoNegociarSelecionado: null,

      tipoNegociacaoSelecionada: { id: 0, title: 'Liberação' },

      tipoNegociacao: [
        { id: 0, title: 'Liberação' },
        { id: 1, title: 'Exclusividade' },
      ],

      tempoVendaSelecionada: {id: 0, title: '12 meses' },
      tempoVenda: [
        { id: 0, title: '12 meses' },
        { id: 1, title: '18 meses' },
        { id: 2, title: '24 meses' },
      ],


      hitValorProposta: 0,

      respostaApi: false,
      hitIdPlaylistSelecionado: null,
      adicionarMusicaPlaylistSideBarAtivo: false,
    }
  },
  watch: {
    tipoNegociacaoSelecionada(newValue) {
      if (newValue.id === 0) {
        this.hitValorProposta = this.hitValorLiberacaoNegociarSelecionado
      } else if (newValue.id === 1) {
        this.hitValorProposta = this.hitValorExclusividadeNegociarSelecionado
      } else {
        this.hitValorProposta = this.hitValorLiberacaoNegociarSelecionado
      }

    },
    filtroReproduzido(newValue, oldValue) {
      // Verifica se o novo valor é diferente do antigo para evitar chamadas desnecessárias
      if (newValue !== oldValue) {
        // Limpa a lista de hits antes de recarregar
        this.hits = [];
        // Reinicia a página para o primeiro conjunto de dados
        this.page = 0;
        // Chama a função hitsLista para carregar os hits com o novo filtro
        this.hitsLista(this.$refs.infiniteLoading);
      }
    },

  },
  created() {
    // this.hitsLista();
    this.hitEstilosLista()
    this.hitTemasLista()
  },
  mounted() {
    // this.hitsLista();
    // this.$refs.hitList.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    // this.$refs.hitList.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    // MODAL NEGOCIAR

    modalNegociar(hitId, hitNomeComposicao, hitValorExclusividade, hitValorLiberacao) {
      this.hitIdNegociarSelecionado = hitId
      this.hitNomeNegociarSelecionado = hitNomeComposicao
      this.hitValorExclusividadeNegociarSelecionado = hitValorExclusividade
      this.hitValorLiberacaoNegociarSelecionado = hitValorLiberacao
      this.tipoNegociacaoSelecionada = { id: 0, title: 'Liberação' }
      this.tempoVendaSelecionada = {id: 0, title: '12 meses' }
      this.hitValorProposta = hitValorLiberacao
      this.$refs.modalNegociar.show()
    },

    // SIDEBAR PLAYLIST
    sidebarPlaylist(hitId) {
      this.hitIdPlaylistSelecionado = hitId;
      this.adicionarMusicaPlaylistSideBarAtivo = true;
    },  
    
    // FAVORITAR HIT

    favoritarHit(hitId, favorito) {
      let promise
      if (!favorito) {
        promise = useJwt.hitFavoritar(hitId)
      } else {
        promise = useJwt.hitDesfavoritar({ hitId })
      }

      promise
        .then(response => {
          const hit = this.hits.find(hit => hit.id === hitId)
          hit.favorito = !hit.favorito
        })
        .catch(error => {
          console.log(response)
        })
    },

    // ALTERAR VALOR LIBERAÇÃO

    alterarValorLiberacao(val) {
      const valorLiberacao = val

      this.filtroLiberacaoMin = valorLiberacao[0]
      this.filtroLiberacaoMax = valorLiberacao[1]
    },

    alterarValorLiberacaoInput() {
      const valorLiberacao = []

      valorLiberacao.push(Number(this.filtroLiberacaoMin))
      valorLiberacao.push(Number(this.filtroLiberacaoMax))

      this.hitValorLiberacao = valorLiberacao
    },

    // ALTERAR VALOR LIBERAÇÃO

    // ALTERAR VALOR LIBERAÇÃO

    alterarValorExclusividade(val) {
      const valorExclusividade = val

      this.filtroExclusividadeMin = valorExclusividade[0]
      this.filtroExclusividadeMax = valorExclusividade[1]
    },

    alterarValorExclusividadeInput() {
      const valorExclusividade = []

      valorExclusividade.push(Number(this.filtroExclusividadeMin))
      valorExclusividade.push(Number(this.filtroExclusividadeMax))

      this.hitValorExclusividade = valorExclusividade
    },

    // ALTERAR VALOR LIBERAÇÃO

    // FORMATAR MOEDA

    formatarMoeda(val) {
      return val.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
    },

    // FORMATAR MOEDA

    // ESTILOS

    hitEstilosLista() {
      useJwt.hitEstilosLista({
        page: 0,
        size: 9999,
        sort: 'ASC',
      })
        .then(response => {

          response.data.content.forEach(estilo => {
            this.estilos.push({ nome: estilo.nome, id: estilo.id })
          })

        })
        .catch(error => {
          console.log(error)
        })
    },

    // TEMAS

    hitTemasLista() {
      useJwt.hitTemasLista({
        page: 0,
        size: 9999,
        sort: 'ASC',
      })
        .then(response => {

          response.data.content.forEach(tema => {
            this.temas.push({ nome: tema.nome, id: tema.id })
          })
        })
        .catch(error => {
          console.log(error)
        })
    },

    // FILTRAR PESQUISA
    filtrarPesquisa() {
      this.page = 0
      this.hits = []
      this.filtrar += 1
    },
    // FILTRAR PESQUISA

    limparFiltro() {

      this.hits = []

      this.hitEstilos = []
      this.hitTemas = []

      this.filtroLiberacaoMin = 0
      this.filtroLiberacaoMax = 100000
      this.filtroExclusividadeMin = 0
      this.filtroExclusividadeMax = 100000

      this.page = 0

      this.filtrar = 0


    },

    // STATUS

    updatePlayList(playList) {
      this.activePlayList = playList
      this.$store.commit('updatePlayList', playList)
    },
    checkActivePlaylist(playlist) {
      return playlist == this.activePlayList
    },


    async hitsLista(infiniteLoading) {

      this.carregamentoApi = true

      useJwt.hitCompradorLista({
        id: this.campanhaId,
        liberacaoMin: this.filtroLiberacaoMin,
        liberacaoMax: this.filtroLiberacaoMax,
        exclusividadeMin: this.filtroExclusividadeMin,
        exclusividadeMax: this.filtroExclusividadeMax,
        listaTemas: this.hitTemas,
        listaEstilos: this.hitEstilos,
        reproduzido: this.filtroReproduzido,
        page: this.page,
        size: this.size,
        sort: this.sort,
      })
        .then(response => {

          if (response.data.content.length) {
            this.page += 1
            this.hits.push(...response.data.content)
            infiniteLoading.loaded()
          } else {
            infiniteLoading.complete()
          }

        })
        .catch(error => {

          infiniteLoading.complete()

        })
        .finally(() => {
          this.carregamentoApi = false
        })

    },

    handleScroll() {

      // this.hitsLista();

    },

    // INSERIR NEGOCIAÇÃO

    negociarHit() {
      this.$refs.negociacaoForm.validate().then(success => {
        if (success) {
          this.respostaApi = true

          useJwt.negociacaoCadastrar({
            hitId: this.hitIdNegociarSelecionado,
            valor: this.hitValorProposta,
            tipoNegociacaoValor: this.tipoNegociacaoSelecionada.id,
            tempoVenda: this.tempoVendaSelecionada.id,
          })
            .then(response => {
              this.$swal({
                title: 'Proposta Enviada!',
                text: 'A sua proposta foi enviada ao compositor, o mesmo será notificado. Você pode acompanhar em "Negociações".',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,

              })
            })
            .catch(error => {
              this.$swal({
                title: 'Erro!',
                text: error.response.data.message,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
            .finally(() => {
              this.respostaApi = false
              this.$refs.modalNegociar.hide()
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-knowledge-base.scss';
@import '@core/scss/mercado-hit.scss';


.mh-play {
  display: block;
  width: 40px;
  height: 40px;
}

.favorito {
  fill: rgb(75, 75, 75);
  color: transparent;
}

.font-awesome-icon-ajustes {
  margin-top: 8px;
}

.transaction-title {
  text-transform: lowercase;
  margin-top: 7px !important;
}

.transaction-title::first-letter {
    text-transform: capitalize;
}

.playlist-dropdown .dropdown-menu {
  left: auto !important; 
  right: 0 !important;  
  transform: translateX(180px);  
}

@media only screen and (max-width: 767px) {
    .transaction-title {
        width: 150px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 12px;
    }

    .tooltip {
      display: none !important;
    }
}

@media (max-width: 370px) {
  .transaction-item {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .transaction-item .b-media {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .transaction-item .b-media-aside {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .transaction-item .b-media-body {
    display: flex;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-bottom: 10px;
  }

  .transaction-item .d-flex {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 10px;
  }

  .media {
    margin-bottom: -10px;
  }

  .boxContainer {
    align-self: center;
    margin-bottom: -65px;
    margin-left: -110%;
  }

  .btPlay {
    align-self: center;
    margin-bottom: -65px;
    margin-left: -100%;
  }
}

</style>
